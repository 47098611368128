import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import PubNub from 'pubnub'
import { getToken, getUser } from '../Login/ManageUser'
import {
  messageButtonabel,
  mediaLabel,
} from '../../constants/enumTypes.constants'
import { moderationStatusCode } from '../../constants/enumTypes.constants'
import { notify } from '../Notify/Notify'
import SideBar from '../common/Sidebar'
import FormField from '../common/FormField'
import Button from '../common/Button/Button'
import ImageUploader from '../common/ImageUploader/ImageUploader'
import axios from 'axios'
import MessageContainer from './MessageContainer'
import './UserMessaging.scss'

const userMessaging = () => {
  // *  useNavigate used for redirect to login
  let navigate = useNavigate()
  // * Authentication variables
  const V2_URL = process.env.REACT_APP_API_V2_URL

  const PUBNUB_PUBLISH_KEY = process.env.REACT_APP_PUBLISH
  const PUBNUB_SUBSCRIBE_KEY = process.env.REACT_APP_SUBSCRIBE

  const pubnub = new PubNub({
    publishKey: PUBNUB_PUBLISH_KEY,
    subscribeKey: PUBNUB_SUBSCRIBE_KEY,
  })
  const channel = process.env.REACT_APP_PUBNUB_CHANNEL
  const senderId = process.env.REACT_APP_PUBNUB_SENDER_ID

  const token = getToken()
  const user = getUser()

  const messageContianerRef = useRef()

  const inputRef = useRef()

  // * Component state
  const [selectedMedia, setSelectedMedia] = useState(mediaLabel[0])
  const [messageText, setMessageText] = useState('')
  const [messageCounter, setMessageCounter] = useState(10)
  const [imgState, setImgState] = useState(null)
  const [imageName, setImageName] = useState()
  const [imgUrl, setImgUrl] = useState()
  const [imageDisplay, setImageDisplay] = useState()
  const [vidSource, setVidSource] = useState(null)
  const [articleChecked, setArticleChecked] = useState(false)
  const [browserLink, setBrowserLink] = useState('')
  const [checkedArticle, setCheckedArticle] = useState()
  const [GIFChecked, setGIFChecked] = useState(false)
  const [imageChecked, setImageChecked] = useState(false)
  const [videoChecked, setVideoChecked] = useState(false)
  const [GIFLink, setGIFLink] = useState('')
  const [checkedGIF, setCheckedGIF] = useState()
  const [ButtonLabel, setButtonLabel] = useState('')
  const [receivedAttachmentLink, setReceivedAttachmentLink] = useState('')
  const [selectedMessageButton, setSelectedMessageButton] = useState(
    messageButtonabel[0]
  )
  const [confirmText, setConfirmText] = useState('')
  const [userId, setUserId] = useState()
  const [userName, setUserName] = useState('')
  const [name, setName] = useState('')
  const [messageAvatar, setMessageAvatar] = useState('')
  const [messageUserName, setMessageUserName] = useState('')
  const [userStatus, setUserStatus] = useState('')
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState([])
  const [errors, setErrors] = useState({})
  const [testUser, setTestUser] = useState()
  const [testName, setTestName] = useState('')
  const [testUserName, setTestUserName] = useState('')
  const [testUserStatus, setTestUserStatus] = useState('')
  const [testUserChecked, setTestUserChecked] = useState(false)

  useEffect(() => {
    fetchHistory()

    pubnub.addListener({
      message: function (event) {
        setMessages(response.channels[channel])
      },
    })

    pubnub.subscribe({
      channels: [channel],
    })

    return () => {
      pubnub.unsubscribeAll()
    }
  }, [channel])

  useEffect(() => {
    handleMessageValidation()
  }, [errors])

  const handleScroll = useCallback(() => {
    if (messageContianerRef.current.scrollTop === 0 && !loading) {
      fetchHistory()
    }
  }, [loading])

  useEffect(() => {
    const container = messageContianerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  const fetchHistory = async () => {
    setLoading(true)
    try {
      const response = await pubnub.fetchMessages({
        channels: [channel],
        count: messageCounter, // number of messages to fetch
        includeMeta: true,
      })
      setMessages(response.channels[channel])

      fetchMessageUserData(
        response.channels[channel]?.[0]?.meta?.messageFromUserId
      )
      setMessageCounter(messageCounter + 10)
      if (messageCounter < 11) {
        setTimeout(() => {
          messageContianerRef.current.scrollTo({
            top: messageContianerRef.current.scrollHeight,
            behavior: 'smooth',
          })
        }, 500)
      }
    } catch (error) {
      console.error('Error fetching message history:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleConfirmTextChange = (e) => {
    setConfirmText(e.target.value)
  }

  const onImageChange = (picture) => {
    var maxLength = 5
    if (picture[0].file) {
      setImgState(picture[0].file)
      setImageName(picture[0].file.name)

      if (picture[0].file.name.length > maxLength) {
        setImageDisplay(picture[0].file.name.substr(0, maxLength) + '...')
      } else {
        setImageDisplay(picture[0].file.name)
      }
    }
  }

  const handleVidFileChange = (event) => {
    const file = event.target.files[0]
    setVidSource(file)
  }

  const handleInputChange = (e, name) => {
    switch (name) {
      case 'messageText':
        setMessageText(e.target.value)
        break
      case 'browserLink':
        setBrowserLink(e.target.value)
        break
      case 'GIF':
        setGIFLink(e.target.value)
        break
      case 'Button-Label':
        setButtonLabel(e.target.value)
        setErrors({})
        break
      case 'user-id':
        setUserId(e.target.value)
        break
      case 'test-id':
        setTestUser(e.target.value)
        break
      default:
        break
    }
  }

  const checkArticle = () => {
    setArticleChecked(true)
    setCheckedArticle(browserLink)
    if (errors) {
      setErrors({})
    }
  }

  const checkGIFLink = () => {
    GIFLink.length > 0 && setGIFChecked(true)
    setCheckedGIF(GIFLink)
    if (errors) {
      setErrors({})
    }
  }

  const handleRemove = () => {
    setImgState(null)
    setImageName('')
    setImageChecked(false)
    setReceivedAttachmentLink('')
  }

  const fetchUserData = (user, search) => {
    let url = `${V2_URL}user/${user}`
    axios
      .get(url, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.data != null) {
          const title = 'Success'
          const detail = res?.data?.message
          const type = 'success'
          let user = res?.data?.data
          notify(title, detail, type)
          let statusMap = moderationStatusCode.find(
            (element) => element.id === user.status
          )
          if (search === 'chat') {
            setUserName(user?.username)
            setName(user?.name)
            setUserStatus(statusMap?.status)
          }
          if (search === 'test') {
            setTestUserName(user?.username)
            setTestName(user?.name)
            setTestUserStatus(statusMap?.status)
            setTestUserChecked(true)
          }
          setErrors({})
        }
      })
  }

  const fetchMessageUserData = (user) => {
    let url = `${V2_URL}user/${user}`
    axios
      .get(url, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        let user = res?.data?.data
        if (res.data.data != null) {
          setMessageUserName(user?.username)
          setMessageAvatar(user?.profile_image)
        }
      })
  }

  const attachmentUpload = () => {
    let url = `${V2_URL}upload`
    const form_data = new FormData()
    if (selectedMedia?.title === 'Photo') {
      form_data.append('file', imgState)
    }
    if (selectedMedia?.title === 'Video') {
      form_data.append('file', vidSource)
    }
    axios
      .post(url, form_data, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.success === true) {
          const title = 'Success'
          const detail = res?.data?.message
          const type = 'success'
          setReceivedAttachmentLink(res?.data?.data?.url)
          selectedMedia?.title === 'Photo'
            ? setImageChecked(true)
            : setVideoChecked(true)
          setErrors({})
          notify(title, detail, type)
        }
      })
  }

  const handleMessageValidation = () => {
    let error = {}
    if (selectedMedia.title === 'Photo' && receivedAttachmentLink === '') {
      error.imgState = 'Image is required'
    }
    if (selectedMedia.title === 'Video' && !videoChecked) {
      error.vidSource = 'Video is required'
    }
    if (selectedMedia.title === 'GIF' && !GIFChecked) {
      error.GIFChecked = 'GIF is required'
    }
    if (selectedMessageButton.title === 'Browser' && !articleChecked) {
      error.articleChecked = 'Browser Link is required'
    }
    if (selectedMessageButton.title === 'Chat' && !userId) {
      error.userId = 'User ID is required'
    }
    if (
      (selectedMessageButton.title === 'Browser' ||
        selectedMessageButton?.title === 'Chat') &&
      ButtonLabel === ''
    ) {
      error.ButtonLabel = 'Button Label is required'
    }
    return error
  }

  const sendMessage = (sendTo) => {
    let buttonMessage = {}
    let attachment = {}
    if (selectedMessageButton?.title === 'Browser') {
      buttonMessage = {
        action: 'browser',
        buttonText: ButtonLabel,
        browserUrl: browserLink,
      }
    }
    if (selectedMessageButton?.title === 'Chat') {
      buttonMessage = {
        action: 'screen',
        buttonText: ButtonLabel,
        screenName: 'chat',
        id: userId,
      }
    }
    if (selectedMedia?.title === 'Photo') {
      attachment = {
        type: '3',
        url: receivedAttachmentLink,
      }
    }
    if (selectedMedia?.title === 'GIF') {
      attachment = {
        type: '1',
        url: GIFLink,
      }
    }
    if (selectedMedia?.title === 'Video') {
      attachment = {
        type: '2',
        url: receivedAttachmentLink,
      }
    }

    var fieldErrors = handleMessageValidation()
    setErrors(fieldErrors)
    if (Object.keys(fieldErrors)?.length === 0) {
      const form_data = new FormData()
      form_data.append('senderId', parseInt(senderId))
      form_data.append('receipents', sendTo === 'all' ? 'all-users' : 'test')
      if (messageText.length > 0) {
        form_data.append('message', messageText)
      }
      form_data.append(
        'messageTemplate',
        selectedMessageButton?.title === 'None' ? 'standard' : 'button'
      )
      if (selectedMedia?.title !== 'None') {
        form_data.append('attachments', JSON.stringify([attachment]))
      }

      if (selectedMessageButton?.title !== 'None') {
        form_data.append('button', JSON.stringify(buttonMessage))
      }

      if (sendTo === 'test') {
        form_data.append('testerUserId', testUser)
      }

      let url = `${V2_URL}sendMessage`
      axios
        .post(url, form_data, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res?.data?.success === true) {
            const title = 'Success'
            const detail = res?.data?.message
            const type = 'success'
            notify(title, detail, type)
            fetchHistory()
            if (sendTo === 'all') {
              setSelectedMedia(mediaLabel[0])
              setSelectedMessageButton(messageButtonabel[0])
              setMessageText('')
              setImgState(null)
              setImageName()
              setImageDisplay()
              setVidSource(null)
              setArticleChecked(false)
              setBrowserLink('')
              setCheckedArticle()
              setGIFChecked(false)
              setImageChecked(false)
              setVideoChecked(false)
              setGIFLink('')
              setCheckedGIF()
              setButtonLabel('')
              setReceivedAttachmentLink('')
              setConfirmText('')
              setUserId()
              setUserName('')
              setName('')
              setUserStatus('')
              setTestUser()
              setTestUserName('')
              setTestName('')
              setTestUserStatus('')
              setErrors({})
              setTestUserChecked(false)
            }
          }
        })
    }
  }

  return (
    <>
      {token && user ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-2">
              <SideBar currentSelected="User Messaging" />
            </div>
            <div className="col-6 col-lg-8 Event_Container moderation-list">
              <p className="Roboto_Black d-flex moderation-list-heading mb-5">
                {`User messaging (FanAmp Official Channel)`}
              </p>
              <div className="moderation-list-center-container p-3 mt-3">
                <div className="d-flex">
                  <div className="col-7">
                    <div className="mt-3">
                      <label className="notification-alert mb-4">
                        <b>
                          THIS SENDS MESSAGES TO <u>ALL APP USERS</u>. Use with
                          extreme caution.
                        </b>
                      </label>
                      <p>
                        Use this dashboard to compose and send messages to one
                        or more FanAmp Users. These messages will appear in each
                        recipient’s private FanAmp Official Chat Channel. Only
                        selected FanAmp team members may send messages on that
                        Channel, and it is read-only for recipients.
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="">
                          <FormField
                            name="Recipient(s)"
                            type="text"
                            label="Recipient(s)"
                            para="Choose who this message is addressed to."
                            placeholder="All FanAmp Users"
                            disabled={true}
                            paraVisibility={true}
                            characterLeftVisible={false}
                          />
                          <FormField
                            name="Sender"
                            type="text"
                            label="Sender"
                            para="Choose which User sends the message."
                            placeholder="Kallmemaybe (ID #48)"
                            disabled={true}
                            paraVisibility={true}
                            characterLeftVisible={false}
                          />
                        </div>
                      </div>
                      <FormField
                        className="col-11"
                        name="Message"
                        type="textarea"
                        label="Message"
                        para="Capped at 2000 characters."
                        placeholder="Type your message here"
                        value={messageText}
                        paraVisibility={true}
                        characterLeft="TBD"
                        characterLeftVisible={true}
                        onChange={(e) => handleInputChange(e, 'messageText')}
                      />
                      <div>
                        <label>
                          <b>Media</b>
                        </label>
                        <p>
                          Optional: Choose which type of media, if any, to
                          include with the message.
                        </p>
                        <div className="row message-wrapper">
                          {[...mediaLabel]?.map((item) => (
                            <div
                              className={`message-label btn m-2 ${
                                Number(selectedMedia?.id) === Number(item?.id)
                                  ? 'active'
                                  : ''
                              }`}
                              key={`category-${item?.id}`}
                              onClick={() =>
                                setSelectedMedia(mediaLabel?.[item?.id])
                              }
                            >
                              <div className="mt-2">{item?.title}</div>
                            </div>
                          ))}
                        </div>
                        {selectedMedia?.title === 'Photo' && (
                          <div>
                            <div className="d-flex align-items-center">
                              <ImageUploader
                                className="mt-3 ml-2"
                                value={imgState}
                                imgUrl={imgUrl}
                                imageName={imageName}
                                onChange={onImageChange}
                                onClick={handleRemove}
                                imageDisplay={imageDisplay}
                              />
                              <Button
                                name="Upload"
                                className="col-3 ml-3"
                                onClick={() => attachmentUpload()}
                              />
                            </div>
                            {imageChecked && (
                              <a
                                href={receivedAttachmentLink ?? '#'}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Open Image URL
                              </a>
                            )}
                            <div>
                              {errors && (
                                <div className="input-feedback">
                                  {errors.imgState}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {selectedMedia?.title === 'Video' && (
                          <div>
                            <label>
                              IMPORTANT :
                              <a
                                href="https://docs.google.com/document/d/1cSLBGE_8es070IHXq_v4XE6l04Qly87dkJbozCx9Dz4"
                                target="_blank"
                                rel="noreferrer"
                              >
                                {' '}
                                Use these instruction{' '}
                              </a>
                              to ensure the video is compressed BEFORE uploading
                              it.
                            </label>
                            <div className="d-flex align-items-center mt-2">
                              <input
                                ref={inputRef}
                                className="mb-0"
                                type="file"
                                onChange={handleVidFileChange}
                                accept=".mov,.mp4"
                              />
                              <Button
                                name="Upload"
                                className="col-3 ml-3 mt-0"
                                onClick={() => attachmentUpload()}
                              />
                            </div>
                            {videoChecked && (
                              <a
                                href={receivedAttachmentLink ?? '#'}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Open Video URL
                              </a>
                            )}
                            {errors && (
                              <div className="input-feedback">
                                {errors.vidSource}
                              </div>
                            )}
                          </div>
                        )}
                        {selectedMedia?.title === 'GIF' && (
                          <div>
                            <div className="d-flex align-items-center">
                              <div className=" col-9 p-0 mr-2 mb-2">
                                <FormField
                                  name="GIF"
                                  type="text"
                                  value={GIFLink ?? ''}
                                  para="Upload the GIF to S3 with public viewing access and then paste the URL here. Do not use a public URL."
                                  placeholder="Enter GIF Link"
                                  paraVisibility={true}
                                  characterLeftVisible={false}
                                  onChange={(e) => handleInputChange(e, 'GIF')}
                                />
                              </div>
                              <div className="mt-5">
                                <Button
                                  name="Check GIF"
                                  onClick={() => checkGIFLink()}
                                  disabled={GIFLink.length === 0}
                                />
                              </div>
                            </div>
                            {GIFChecked && (
                              <a
                                href={checkedGIF ?? '#'}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Open GIF URL
                              </a>
                            )}
                            {errors && (
                              <div className="input-feedback">
                                {errors.GIFChecked}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="mt-2">
                        <label>
                          <b>Button</b>
                        </label>
                        <p>
                          Optional: Choose which type of button, if any, to
                          include with the message.
                        </p>
                        <div className="row message-wrapper">
                          {[...messageButtonabel]?.map((item) => (
                            <div
                              className={`message-label btn m-2 ${
                                Number(selectedMessageButton?.id) ===
                                Number(item?.id)
                                  ? 'active'
                                  : ''
                              }`}
                              key={`category-${item?.id}`}
                              onClick={() =>
                                setSelectedMessageButton(
                                  messageButtonabel?.[item?.id]
                                )
                              }
                            >
                              <div className="mt-2">{item?.title}</div>
                            </div>
                          ))}
                        </div>
                        {selectedMessageButton?.title === 'Browser' && (
                          <div>
                            <div className="d-flex align-items-center">
                              <FormField
                                name="Browser"
                                type="text"
                                value={browserLink ?? ''}
                                className="col-11"
                                placeholder="Enter Browser Link"
                                paraVisibility={true}
                                characterLeftVisible={false}
                                onChange={(e) =>
                                  handleInputChange(e, 'browserLink')
                                }
                              />
                              <div className="mt-1">
                                <Button
                                  name="Check Article"
                                  onClick={() => checkArticle()}
                                />
                              </div>
                            </div>
                            {articleChecked && (
                              <a
                                href={checkedArticle ?? '#'}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Article URL
                              </a>
                            )}
                            {errors && (
                              <div className="input-feedback">
                                {errors.articleChecked}
                              </div>
                            )}
                          </div>
                        )}
                        {selectedMessageButton?.title === 'Chat' && (
                          <div>
                            <div className="d-flex align-items-center">
                              <FormField
                                name="Browser"
                                type="text"
                                className="col-8"
                                placeholder="Enter UserID"
                                paraVisibility={true}
                                characterLeftVisible={false}
                                value={userId}
                                onChange={(e) =>
                                  handleInputChange(e, 'user-id')
                                }
                              />

                              <div className="mt-1">
                                <Button
                                  name="Check User"
                                  onClick={() => fetchUserData(userId, 'chat')}
                                />
                              </div>
                            </div>
                            {errors && (
                              <div className="input-feedback">
                                {errors.userId}
                              </div>
                            )}
                            <div>
                              <div>
                                <b>Username: </b>
                                {userName}
                              </div>
                              <div>
                                <b>Name:</b> {name}
                              </div>
                              <div>
                                <b>Status: </b>
                                {userStatus}
                              </div>
                            </div>
                          </div>
                        )}
                        {(selectedMessageButton?.title === 'Chat' ||
                          selectedMessageButton?.title === 'Browser') && (
                          <div className="mt-3">
                            <FormField
                              name="Button-Label"
                              type="text"
                              label="Button Label"
                              value={ButtonLabel ?? ''}
                              className="col-6"
                              paraVisibility={true}
                              characterLeftVisible={true}
                              characterLeft="TBD"
                              para="Capped at 25 characters."
                              placeholder="Type Button Label here"
                              onChange={(e) =>
                                handleInputChange(e, 'Button-Label')
                              }
                            />
                            {errors && (
                              <div className="input-feedback">
                                {errors.ButtonLabel}
                              </div>
                            )}
                          </div>
                        )}
                        <hr />
                        <div>
                          <label>
                            {' '}
                            <b>Test then send your messge</b>
                          </label>
                          <div>
                            <div className="d-flex align-items-center">
                              <FormField
                                name="Browser"
                                type="text"
                                className="col-8"
                                placeholder="Enter UserID"
                                paraVisibility={true}
                                characterLeftVisible={false}
                                value={testUser}
                                onChange={(e) =>
                                  handleInputChange(e, 'test-id')
                                }
                              />

                              <div className="mt-1">
                                <Button
                                  name="Check User"
                                  onClick={() =>
                                    fetchUserData(testUser, 'test')
                                  }
                                />
                              </div>
                            </div>
                            {errors && (
                              <div className="input-feedback">
                                {errors.userId}
                              </div>
                            )}
                            <div>
                              <div>
                                <b>Username: </b>
                                {testUserName}
                              </div>
                              <div>
                                <b>Name:</b> {testName}
                              </div>
                              <div>
                                <b>Status: </b>
                                {testUserStatus}
                              </div>
                            </div>
                          </div>
                          <div className="flex-grow-1 mt-3">
                            <Button
                              name="Test"
                              className="w-25"
                              onClick={() => sendMessage('test')}
                              disabled={
                                (selectedMedia?.title === 'None' &&
                                  selectedMessageButton?.title === 'None' &&
                                  messageText.length === 0) ||
                                !testUserChecked
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-8"></div>
                      <div className="ml-2">
                        <p className="mt-3">
                          {' '}
                          {
                            'When you’re ready to send, type the message shown in the box below and then click the orange “Send” button.'
                          }
                        </p>
                        <FormField
                          className="col-3"
                          value={confirmText ?? ''}
                          hideLabel={true}
                          name="Title"
                          type="text"
                          placeholder="notify fanamp users"
                          autoComplete="off"
                          onChange={(e) => handleConfirmTextChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-5 message-history-wrapper">
                    <div className="mt-3">
                      <label className="mb-4">
                        <b>Message History</b>
                      </label>
                    </div>
                    <div
                      className="p-2 message-history-container d-flex flex-column gap-3"
                      ref={messageContianerRef}
                    >
                      {messages
                        ? messages?.map((message, index) => {
                            const previousMessageTimestamp =
                              index > 0 ? messages[index - 1].timetoken : null

                            return (
                              <MessageContainer
                                userName={messageUserName}
                                userAvatar={messageAvatar}
                                item={message}
                                content={message?.message}
                                key={message?.timetoken}
                                timestamp={message?.timetoken}
                                previousMessageTimestamp={
                                  previousMessageTimestamp
                                }
                              />
                            )
                          })
                        : 'Messages Not Found'}
                    </div>
                  </div>
                </div>
                <div className="w-25 ms-3">
                  <Button
                    name="Send"
                    onClick={() => sendMessage('all')}
                    disabled={confirmText !== 'notify fanamp users'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
    </>
  )
}
export default userMessaging
