import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import axios from 'axios'
import './constants/custom-axios'

// react-toastify
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import LoginPage from './pages/login'
import SettingsPage from './pages/settings'
import EventDataFeeds from './components/EventDataFeeds/EventDataFeeds'
import Gamification from './components/Gamification/Gamification'
import AssociatingProviders from './components/AssociatingPoviders/AssociatingProviders'
import W2WProvidersList from './components/Events/W2WProviders/W2WProvidersList/W2wProvidersList'
import DetailsEvent from './components/Events/DetailsEvent'
import CreateEvent from './components/Events/CreateEvent/CreateEvent'
import PasswordValidation from './components/Password/PasswordValidation'
import ForgetPassword from './components/Password/ForgetPassword/ForgetPassword'
import EventInfo from './components/EventInfo/EventInfo'
import Tickets from './components/Tickets/Tickets'
import CreateEventInfo from './components/EventInfo/CreateEventInfo'
import CoreContent from './components/EventInfo/CoreContent'
import Schedule from './components/EventInfo/Schedule'
import Articles from './components/EventInfo/Articles'
import EventGroups from './components/EventGroups/EventGroups'
import Sectors from './components/Sectors/Sectors'
import Seasons from './components/Seasons/Seasons'
import Statistics from './components/Statistics/Statistics'
import News from './components/News/News'
import UserMessaging from './components/UserMessaging/UserMessaging'
import Moderation from './components/Moderation/Moderation'
import Notifications from './components/Notifications/Notificaitons'
import PubNub from 'pubnub'
import { PubNubProvider } from 'pubnub-react'


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  beforeSend(event, hint) {
    // Check if the error is an HTTP error
    if (
      hint?.originalException instanceof Error &&
      [400, 401, 404, 500].includes(hint?.originalException.response?.status)
    ) {
      // Return null to indicate that the error should be ignored
      return null
    }
    // Return the event to send the error to Sentry
    return event
  },
})

const pubnub = new PubNub({
  publishKey: process.env.REACT_APP_PUBLISH,
  subscribeKey: process.env.REACT_APP_SUBSCRIBE,
  uuid: process.env.REACT_APP_UUID,
})

ReactDOM.render(
  <PubNubProvider client={pubnub}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route exact path="reset-password" element={<PasswordValidation />} />
          <Route exact path="forget-password" element={<ForgetPassword />} />
          <Route exact path="events">
            <Route exact path="eventdetails">
              <Route path="" element={<DetailsEvent />} />
              <Route exact path="create-event" element={<CreateEvent />} />
              <Route exact path="edit-event">
                <Route path="" element={<DetailsEvent />} />
                <Route
                  exact
                  path="associate-w2w"
                  element={<AssociatingProviders />}
                />
                <Route exact path="gamification" element={<Gamification />} />
                <Route exact path="data-feeds" element={<EventDataFeeds />} />
                <Route path=":id" element={<CreateEvent />} />
              </Route>
            </Route>
            <Route exact path="eventinfo">
              <Route path="" element={<EventInfo />} />
              <Route
                exact
                path="create-eventinfo"
                element={<CreateEventInfo />}
              />
              <Route exact path="edit-eventinfo">
                <Route path="" element={<EventInfo />} />
                <Route path=":id" element={<CreateEventInfo />} />
                <Route exact path="core-content" element={<CoreContent />} />
                <Route exact path="schedule" element={<Schedule />} />
                <Route exact path="articles" element={<Articles />} />
              </Route>
            </Route>
            <Route exact path="tickets" element={<Tickets />} />
            <Route exact path="eventgroups" element={<EventGroups />} />
            <Route exact path="sectors" element={<Sectors />} />
            <Route exact path="seasons" element={<Seasons />} />
            <Route exact path="statistics" element={<Statistics />} />
            <Route exact path="w2wproviders" element={<W2WProvidersList />} />
          </Route>
          <Route exact path="notifications" element={<Notifications />} />
          <Route exact path="userMessaging" element={<UserMessaging />} />
          <Route exact path="news" element={<News />} />
          <Route exact path="moderation" element={<Moderation />} />
          <Route exact path="settings" element={<SettingsPage />} />
          <Route path="*" element={<LoginPage />} />
        </Routes>
        <App />
        <ToastContainer />
      </BrowserRouter>
    </React.StrictMode>
  </PubNubProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
